import React, { useCallback, useContext, useMemo } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import { StaticImage } from 'gatsby-plugin-image';
import ReactTooltip from 'react-tooltip';
import { BsPlayFill } from 'react-icons/bs';
import produce from 'immer';
import { Card } from '@/components/card';
import {
  EditorChangeKind,
  EditorDeclarativeBlock,
  TargetingDeviceType,
  toTargetingDeviceType,
} from '@/webapi/use-experience-api';
import { DeviceType, WidgetType } from '@/utils/definitions';
import { ChangelogTooltip } from '@/features/editor/widgets/changelog/changelog-tooltip';
import { truncate } from '@/utils/types';
import { ChangeTarget } from '@/features/editor/widgets/changelog/change-target-button';
import { EditorContext } from '@/features/editor/context/editor-context';
import { shouldSync } from '@/utils/experience-utils';
import { getOriginExperienceName } from '@/features/editor/shared/external_experience_widget';
import { CatalogApp } from '@/webapi/use-widget-catalog-api';
import {
  ChangeScreenshot,
  getScreenshotFromChange,
} from '@/utils/change-screenshot';
import { AccountContext } from '@/features/account-context';
import {
  AutomationChange,
  CheckoutWidgetChange,
  HtmlMutationKind,
  MoveChange,
  WidgetChange,
} from '@/pkg/sdk';
import { conditional } from '@/utils/conditional';
import { hash } from '@/utils/cache';

export interface ChangelogTileProps {
  ordinal: number;
  changeType: WidgetType;
  change: EditorDeclarativeBlock;

  device: DeviceType;

  onClick?: () => void;
  onHover?: () => void;
}

export function ChangelogTile({
  ordinal,
  device,
  onClick,
  onHover,
  change,
}: ChangelogTileProps) {
  const {
    account: {
      store: { alias },
    },
  } = useContext(AccountContext);
  const {
    resources: { appsCatalog },
    experienceState: {
      currentExperience,
      upsertEditorChange,
      currentVariantIdx,
    },
    devicePreview: { automatePlay },
  } = useContext(EditorContext);
  const isSynced = useMemo(
    () => shouldSync(currentExperience, change),
    [currentExperience],
  );

  const screenshot = useMemo(
    () =>
      getScreenshotFromChange(
        alias,
        currentExperience.id,
        currentExperience.variants[0],
        change,
      ),
    [currentExperience, change],
  );

  const onBeforeClick = () => {
    if (isSynced && onClick) onClick();
  };

  const onAutomationPlay = (ev) => {
    ev.preventDefault();
    ev.stopPropagation();
    automatePlay((change?.block?.value as AutomationChange).steps);
  };

  const onToggleVisibility = useCallback(() => {
    const temp = produce(change, (draft) => {
      draft.isDisabled = !draft?.isDisabled;
    });

    upsertEditorChange(temp);
  }, [currentExperience, currentVariantIdx]);

  const shouldShowVisibilityToggle = useMemo(() => {
    if (change.targetDevice === TargetingDeviceType.ALL_DEVICES) {
      return true;
    }
    return toTargetingDeviceType(device) === change.targetDevice;
  }, [device, change]);

  return (
    <Wrapper
      onMouseEnter={onHover}
      onClick={onBeforeClick}
      device={device}
      isSynced={isSynced}
    >
      <SelectorTooltipWrapper />
      {change?.block?.kind === `automation` ? (
        <PlayAutomation device={device} onClick={onAutomationPlay}>
          <BsPlayFill size={18} />
        </PlayAutomation>
      ) : (
        <Ordinal device={device}>{ordinal}</Ordinal>
      )}
      <Title device={device}>
        {titleOf(change, ordinal, appsCatalog, screenshot)}
        {` `}
        <ChangeTarget isSynced={isSynced} change={change} />
      </Title>
      <Controls>
        <VisibilityToggle
          change={change}
          isSynced={shouldShowVisibilityToggle}
          onClick={onToggleVisibility}
        />
        <Icon data-tip data-for={change.editorId}>
          <StaticImage
            height={20}
            placeholder="none"
            loading="eager"
            src="../../../../assets/three_dots_2.svg"
            alt="close"
          />
        </Icon>
      </Controls>
      <ChangelogTooltip change={change} isSynced={isSynced} />
    </Wrapper>
  );
}

const simpleActionTitle = (
  prefix: string,
  change: EditorDeclarativeBlock,
  screenshot: ChangeScreenshot,
): JSX.Element => {
  const changeTooltipId = useMemo(() => `change-${change.editorId}`, [change]);
  const { editorId, hint } = change;
  return (
    <>
      {!!hint && (
        <>
          <span data-tip data-for={changeTooltipId}>
            {hint}
          </span>
          <SelectorTooltip
            id={changeTooltipId}
            selector={`${prefix} ${change.editorSelector}`}
            image={screenshot.changeUrl}
          />
        </>
      )}
      {!hint && (
        <>
          {prefix}:{` `}
          <SelectorSection
            image={screenshot.mountUrl}
            editorId={editorId}
            selector={change.editorSelector}
          />
        </>
      )}
    </>
  );
};

const moveActionTitle = (
  change: EditorDeclarativeBlock,
  screenshot: ChangeScreenshot,
): JSX.Element => {
  const { htmlKind } = change.block.value as MoveChange;
  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {change?.hint ? (
        change?.hint
      ) : (
        <>
          Moving:{` `}
          <SelectorSection
            image={screenshot.mountUrl}
            editorId={change.editorId}
            selector={change.block.selector}
          />
          {` `}
          <Directive>
            {conditional(
              `instead of`,
              htmlKind === `appendBefore`,
              `before`,
              htmlKind === `appendAfter`,
              `after`,
            )}
          </Directive>
          <SelectorSection
            image={screenshot.changeUrl}
            editorId={change.editorId}
            selector={(change.block.value as MoveChange).destSelector}
          />
        </>
      )}
    </>
  );
};

const widgetActionTitle = (
  prefix: string,
  directive: string,
  change: EditorDeclarativeBlock,
  screenshot: ChangeScreenshot,
  appsCatalog: CatalogApp[],
): JSX.Element => {
  const originExperienceName = getOriginExperienceName(change);
  const changeTooltipId = useMemo(() => `change-${change.editorId}`, [change]);

  if (originExperienceName) {
    return (
      // eslint-disable-next-line react/jsx-no-useless-fragment
      <ExternalWidgetTitle>
        <span>
          {change?.hint && (
            <>
              change?.hint <br />
            </>
          )}
          Personalization of experience{` `}
          <b>
            <strong>{originExperienceName}</strong>
          </b>
        </span>
      </ExternalWidgetTitle>
    );
  }

  const widgetName = conditional(
    getWidgetName(appsCatalog, change),
    change.block.kind === `checkoutWidget`,
    getCheckoutWidgetName(change),
  );

  return (
    <>
      {!!change?.hint && (
        <>
          <span data-tip data-for={changeTooltipId}>
            {change.hint}
          </span>
          <SelectorTooltip
            id={changeTooltipId}
            selector={`${prefix} ${widgetName} ${directive} ${change.block.selector}`}
            image=""
          />
        </>
      )}
      {!change?.hint && (
        <>
          {change.block.kind !== `compound` && (
            <SelectorTooltip
              id={changeTooltipId}
              selector={
                (change?.block?.value as WidgetChange)?.env?.[`sectionId`] ||
                `#${
                  (change?.block?.value as CheckoutWidgetChange)?.sectionId ||
                  ``
                }`
              }
              image={screenshot.changeUrl}
            />
          )}
          <span data-tip data-for={changeTooltipId}>
            {prefix}
            {` `}
            <b>{widgetName}</b>
          </span>
          {` `}
          <Directive>{directive}</Directive>
          <SelectorSection
            image={screenshot.mountUrl || screenshot.changeUrl}
            editorId={change.editorId}
            selector={
              change.block.kind === `checkoutWidget`
                ? change?.checkoutMountPointInfo?.mountPointSelector
                : change.block.selector
            }
          />
        </>
      )}
    </>
  );
};

const titleOf = (
  change: EditorDeclarativeBlock,
  ordinal: number,
  appsCatalog: CatalogApp[],
  screenshot: ChangeScreenshot,
): JSX.Element => {
  if (
    change.editorKind === EditorChangeKind.EDIT_COMPOUND ||
    change.editorKind === EditorChangeKind.VISUAL_EDIT
  ) {
    return simpleActionTitle(`Editing`, change, screenshot);
  }

  if (change.editorKind === EditorChangeKind.HIDE_COMPONENT) {
    return simpleActionTitle(`Hiding`, change, screenshot);
  }

  if (
    change.editorKind === EditorChangeKind.GLOBAL_CSS ||
    change.editorKind === EditorChangeKind.GLOBAL_JS
  ) {
    return (
      <>
        Adding{` `}
        <b>
          {change.editorKind === EditorChangeKind.GLOBAL_CSS
            ? `Global CSS Stylesheet`
            : `Global Javascript`}
        </b>
      </>
    );
  }

  if (change.editorKind === EditorChangeKind.NEW_COMPONENT) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const htmlKind = change?.block?.value?.htmlKind as HtmlMutationKind;

    const prefix = conditional(
      `Adding`,
      (change.block.kind === `checkoutWidget` &&
        (change.block.value as CheckoutWidgetChange)?.op === `replace`) ||
        htmlKind === `replace`,
      `Replacing`,
    );

    const directive = conditional(
      `WITH`,
      change.block.kind === `checkoutWidget` &&
        (change.block.value as CheckoutWidgetChange)?.op !== `replace`,
      `INTO`,
      change.block.kind === `checkoutWidget` &&
        (change.block.value as CheckoutWidgetChange)?.op === `replace`,
      `WITHIN`,
      htmlKind === `appendAfter`,
      `AFTER`,
      htmlKind === `appendBefore`,
      `BEFORE`,
    );

    return widgetActionTitle(
      prefix,
      directive,
      change,
      screenshot,
      appsCatalog,
    );
  }

  if (change.editorKind === EditorChangeKind.MOVE_COMPONENT) {
    return moveActionTitle(change, screenshot);
  }

  if (change.editorKind === EditorChangeKind.FAKE_CLICK) {
    return (
      // eslint-disable-next-line react/jsx-no-useless-fragment
      <>
        {change?.hint ? (
          change?.hint
        ) : (
          <>
            Adding <b>Fake Click</b> Sequence #{ordinal}
          </>
        )}
      </>
    );
  }

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{change?.hint ? change?.hint : <>{change.editorSelector}</>}</>;
};

function getWidgetName(
  appsCatalog: CatalogApp[],
  change: EditorDeclarativeBlock,
): string {
  if (change.block.kind === `compound`) return `Code Block`;

  const widgetId = (change?.block?.value as WidgetChange)?.widgetId || ``;
  const widgets = appsCatalog
    .map((app) => app.widgets.find((widget) => widget.id === widgetId))
    .filter((widget) => !!widget);
  return widgets?.[0]?.name || `element`;
}

function getCheckoutWidgetName(change: EditorDeclarativeBlock): string {
  return change?.checkoutWidgetManifest?.manifest?.name || `element`;
}

const Wrapper = styled(Card)`
  && {
    font-family: Inter, serif;
    cursor: ${(props: { device: DeviceType; isSynced: boolean }) =>
      props.isSynced ? `pointer` : `default`};
    line-height: 1.8;
    border-radius: 1.8rem;
    min-height: 7rem;
    padding: ${(props: { device: DeviceType; isSynced: boolean }) =>
      props.device === DeviceType.Desktop
        ? `1rem 0 1rem 2rem`
        : `1rem 0 1rem 2rem`};
    display: grid;
    grid-template-columns: ${(props: {
      device: DeviceType;
      isSynced: boolean;
    }) =>
      props.device === DeviceType.Desktop
        ? `2rem 1fr 7.5rem`
        : `2rem 1fr 6.5rem`};
    grid-column-gap: ${(props: { device: DeviceType; isSynced: boolean }) =>
      props.device === DeviceType.Desktop ? `1.8rem` : `2rem`};
    align-items: center;
    border: 2px solid rgba(151, 151, 151, 0.1);
    box-shadow: 0 10px 20px 0 rgba(177, 217, 203, 0.18),
      0 2px 15px 0 rgba(0, 0, 0, 0.05);
  }
`;

const Ordinal = styled.div`
  font-family: Inter, serif;
  font-size: ${(props: { device: DeviceType }) =>
    props.device === DeviceType.Desktop ? `2rem` : `2.2rem`};
  font-weight: 500;
  letter-spacing: 0.47px;
  color: #c4d0dc;
  text-align: center;

  display: flex;
  align-items: center;
  justify-content: center;
`;

const PlayAutomation = styled.div`
  position: relative;
  height: ${(props: { device: DeviceType }) =>
    props.device === DeviceType.Desktop ? `2.5rem` : `3rem`};
  width: ${(props: { device: DeviceType }) =>
    props.device === DeviceType.Desktop ? `2.5rem` : `3rem`};
  font-size: ${(props: { device: DeviceType }) =>
    props.device === DeviceType.Desktop ? `1.2rem` : `1.4rem`};
  font-weight: bold;
  letter-spacing: 0.47px;
  background-color: #7c7f98;
  color: white;
  opacity: 0.33;
  text-align: center;
  padding-left: 0.2rem;
  border-radius: 50%;

  display: flex;
  align-items: center;
  justify-content: center;

  :hover {
    transform: scale(1.15);
  }

  :active {
    transform: scale(1);
  }

  transition: transform 0.2s ease-in;

  :before {
    content: '';
    display: block;
    height: 125%;
    width: 125%;
    position: absolute;
    border: 1.5px solid #dce1e5;
    border-radius: 50%;
    margin-left: -0.3rem;
  }
`;

const Title = styled.span`
  color: #8f969d;
  letter-spacing: -0.47px;
  font-size: ${(props: { device: DeviceType }) =>
    props.device === DeviceType.Desktop ? `1.2rem` : `1.4rem`};
  font-weight: normal;

  u {
    text-decoration-thickness: 0.15rem;
  }

  b {
    color: black;
    font-weight: bold;

    :hover {
      text-decoration: underline;
    }
  }
`;

const Controls = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  align-content: center;
  justify-content: center;
  justify-items: center;
`;

const Icon = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 0.5rem;

  cursor: context-menu;

  :hover {
    opacity: 0.6;
  }
`;

const Directive = styled.span`
  color: #8e969d;
  text-transform: lowercase;
  margin-right: 0.3rem;
`;

const ExternalWidgetTitle = styled.div``;

export function SelectorSection({
  selector,
  editorId,
  image,
  limit = 30,
}: {
  selector: string;
  editorId: string;
  image: string;
  limit?: number;
}) {
  const id = useMemo(
    () => `selector-${hash(`${editorId}-${selector}`)}`,
    [editorId],
  );
  const truncatedSelector = useMemo(
    () => truncate(selector, limit),
    [selector, limit],
  );

  return (
    <>
      <SelectorWrapper data-tip data-for={id}>
        {truncatedSelector}
      </SelectorWrapper>
      <SelectorTooltip id={id} selector={selector} image={image} />
    </>
  );
}

const SelectorWrapper = styled.span`
  text-decoration: none;
  color: black;
  font-weight: bold;

  :hover {
    text-decoration: underline;
  }
`;

export const SelectorTooltipWrapper = createGlobalStyle`
  .selector-tooltip {
    display: grid!important;
    justify-content: center;
    align-items: center;
    align-content: center;
    justify-items: center;
    grid-gap: 0.5rem;
    flex-direction: column;
    box-shadow: 0 4px 5px 0 rgba(105, 122, 231, 0.11),
    0 12px 16px 0 rgba(196, 217, 210, 0.38), 0 1px 5px 0 rgba(0, 0, 0, 0.15);
    border-radius: 1rem!important;
    font-family: "JetBrains Mono",serif;
    opacity: 1!important;
    padding: 1.2rem!important;

    img {
      object-fit: contain;
      max-width: 200px;
      min-height: 100px;
    }
  }

`;

export function appendQueryParam(
  url: string,
  key: string,
  val: string,
): string {
  if (url.includes(`?`)) {
    return `${url}&${key}=${val}`;
  }
  return `${url}?${key}=${val}`;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function SelectorTooltip({ id, selector, image }) {
  // const [imageUrl, setImageUrl] = useState(image);

  // const afterShow = () => {
  //   setImageUrl(appendQueryParam(image, `ts`, `${new Date().getTime()}`));
  // };
  //
  // const afterHide = () => {
  //   setImageUrl(``);
  // };
  //
  // const onError = () => {
  //   setImageUrl(``);
  // };

  return (
    // @ts-ignore
    <ReactTooltip
      id={id}
      place="top"
      backgroundColor="#fff"
      textColor="#000"
      className="selector-tooltip"
      // afterShow={afterShow}
      // afterHide={afterHide}
    >
      <span>{selector}</span>
      {/* {!!imageUrl && <img onError={onError} src={imageUrl} alt="" />} */}
    </ReactTooltip>
  );
}

function VisibilityToggle({
  change,
  isSynced,
  onClick,
}: {
  change: EditorDeclarativeBlock;
  isSynced: boolean;
  onClick?: () => void;
}) {
  const onClickWrapper = (ev) => {
    ev.stopPropagation();
    if (onClick) onClick();
  };

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {isSynced ? (
        <VisibilityWrapper
          className={`${change?.isDisabled ? `disabled` : `active`}`}
          onClick={onClickWrapper}
        >
          {change?.isDisabled && (
            <StaticImage
              src="./invisible.svg"
              alt="eye"
              placeholder="none"
              loading="eager"
            />
          )}
          {!change?.isDisabled && (
            <StaticImage
              src="./visible.svg"
              alt="crossed eye"
              placeholder="none"
              loading="eager"
            />
          )}
        </VisibilityWrapper>
      ) : (
        <div />
      )}
    </>
  );
}

const VisibilityWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .gatsby-image-wrapper {
    width: 1.7rem;
  }

  &.active {
    opacity: 0.4;
    cursor: zoom-out;
  }

  &.disabled {
    opacity: 0.4;
    cursor: zoom-in;
  }
`;
