// eslint-disable-next-line max-classes-per-file
import { isBrowser } from '@/utils/browser';
import { getDatabaseTable } from '@/db';

class Cacheable<T> {
  _value: T;

  _addedAt: number;

  key?: string;

  constructor(value: T, addedAt?: number) {
    // eslint-disable-next-line no-underscore-dangle
    this._value = value;
    // eslint-disable-next-line no-underscore-dangle
    this._addedAt = addedAt || new Date().getTime();
  }

  public value(): T {
    // eslint-disable-next-line no-underscore-dangle
    return this._value;
  }

  public addedAt(): number {
    // eslint-disable-next-line no-underscore-dangle
    return this._addedAt;
  }

  public setKey(key: string) {
    this.key = key;
  }
}

interface Backend<T> {
  exists(key: string): Promise<boolean>;
  get(key: string): Promise<Cacheable<T> | undefined>;
  set(key: string, value: Cacheable<T>): Promise<void>;
  remove(key: string): Promise<void>;
}

export abstract class Cache<T> {
  private backend: Backend<T>;

  protected ttlMillis: number;

  protected writesEnabled = true;

  protected constructor(backend: Backend<T>, ttlMillis: number) {
    this.backend = backend;
    this.ttlMillis = ttlMillis;
  }

  protected isValid(value: Cacheable<T> | undefined): boolean {
    if (!value) return false;
    return new Date().getTime() - value.addedAt() <= this.ttlMillis;
  }

  public async get(key: string): Promise<T | undefined> {
    if (!isBrowser()) return undefined;
    const value = await this.backend.get(key);
    const isValid = this.isValid(value);
    if (!!value && isValid) {
      return Promise.resolve(value.value());
    }

    if (!isValid) {
      await this.backend.remove(key);
    }

    return Promise.resolve(undefined);
  }

  public async set(key: string, value: T, addedAt?: number) {
    if (!isBrowser()) return;
    if (!this.writesEnabled) return;
    const cacheable: Cacheable<T> = new Cacheable<T>(value, addedAt);
    await this.backend.set(key, cacheable);
  }

  public async remove(key: string) {
    if (!isBrowser()) return;
    await this.backend.remove(key);
  }

  public async exists(key: string): Promise<boolean> {
    if (!isBrowser()) return false;
    return this.backend.exists(key);
  }

  public disableWrites() {
    this.writesEnabled = false;
  }

  public enableWrites() {
    this.writesEnabled = true;
  }
}

export class WindowCache<T> extends Cache<T> {
  constructor(name: string, ttlMillis: number) {
    super(new WindowBackend(name), ttlMillis);
  }
}

export class LocalStorageCache<T> extends Cache<T> {
  constructor(namespace: string, ttlMillis: number, opts?: Serde<T>) {
    super(new LocalStorageBackend(namespace, opts), ttlMillis);
  }
}

export class IndexedDbCache<T> extends Cache<T> {
  constructor(namespace: string, ttlMillis: number) {
    super(new IndexedDbBackend(namespace), ttlMillis);
  }
}

class WindowBackend<T> implements Backend<T> {
  private readonly namespace: string;

  constructor(namespace: string) {
    this.namespace = `vsly_cache_${namespace}`;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window[this.namespace] = {};
  }

  exists(key: string): Promise<boolean> {
    return Promise.resolve(this.get(key) !== undefined);
  }

  get(key: string): Promise<Cacheable<T> | undefined> {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return Promise.resolve(window[this.namespace]?.[key]);
  }

  remove(key: string): Promise<void> {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    !!window[this.namespace]?.[key] && delete window[this.namespace]?.[key];
    return Promise.resolve(undefined);
  }

  set(key: string, value: Cacheable<T>): Promise<void> {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window[this.namespace][key] = value;
    return Promise.resolve(undefined);
  }
}

export interface Serde<T> {
  serializer?: (t: Cacheable<T>) => string;
  deserializer?: (string) => Cacheable<T> | undefined;
}

class LocalStorageBackend<T> implements Backend<T> {
  private readonly namespace: string;

  private serializer: (t: Cacheable<T>) => string;

  private deserializer: (string) => Cacheable<T>;

  constructor(namespace: string, options?: Serde<T>) {
    const defSer = (t: Cacheable<T>) => JSON.stringify(t);
    const defDes = (s: string) => JSON.parse(s) as Cacheable<T>;
    this.namespace = namespace;
    this.serializer = options?.serializer || defSer;
    this.deserializer = options?.deserializer || defDes;
  }

  private wrapKey(key: string): string {
    return `${this.namespace}_${key}`;
  }

  exists(key: string): Promise<boolean> {
    return Promise.resolve(this.get(key) !== undefined);
  }

  get(key: string): Promise<Cacheable<T> | undefined> {
    const val = localStorage?.getItem(this.wrapKey(key));
    if (!val) return undefined;
    const jsValue = this.deserializer(val);
    if (!jsValue) return undefined;
    return Promise.resolve(
      // eslint-disable-next-line no-underscore-dangle
      new Cacheable<T>(jsValue?._value, jsValue?._addedAt),
    );
  }

  remove(key: string): Promise<void> {
    localStorage.removeItem(this.wrapKey(key));
    return Promise.resolve();
  }

  set(key: string, value: Cacheable<T>): Promise<void> {
    const str = this.serializer(value);
    try {
      localStorage.setItem(this.wrapKey(key), str);
    } catch (ex) {
      console.error(
        `vsly-editor`,
        `failed to set key: ${key} with exception`,
        ex,
      );
    }
    return Promise.resolve(undefined);
  }
}

class IndexedDbBackend<T> implements Backend<T> {
  private readonly namespace: string;

  constructor(namespace: string) {
    this.namespace = namespace;
  }

  exists(key: string): Promise<boolean> {
    return Promise.resolve(this.get(key) !== undefined);
  }

  async get(key: string): Promise<Cacheable<T> | undefined> {
    const table = getDatabaseTable<Cacheable<T>>(this.namespace);
    if (table) {
      const val = (await table.get(key)) as Cacheable<T>;
      if (val) {
        // eslint-disable-next-line no-underscore-dangle
        return Promise.resolve(new Cacheable<T>(val._value, val._addedAt));
      }
    }
    return Promise.resolve(undefined);
  }

  remove(key: string): Promise<void> {
    const table = getDatabaseTable<Cacheable<T>>(this.namespace);
    if (table) {
      return table.delete(key);
    }
    return Promise.resolve(undefined);
  }

  async set(key: string, value: Cacheable<T>): Promise<void> {
    value.setKey(key);
    const table = getDatabaseTable<Cacheable<T>>(this.namespace);
    if (table) {
      await table.put(value);
    }
    return Promise.resolve(undefined);
  }
}

export const hash = function (str: string, seed = 8397271938200529): string {
  // eslint-disable-next-line no-bitwise
  let h1 = 0xdeadbeef ^ seed;
  // eslint-disable-next-line no-bitwise
  let h2 = 0x41c6ce57 ^ seed;
  // eslint-disable-next-line no-plusplus
  for (let i = 0, ch; i < str.length; i++) {
    ch = str.charCodeAt(i);
    // eslint-disable-next-line no-bitwise
    h1 = Math.imul(h1 ^ ch, 2654435761);
    // eslint-disable-next-line no-bitwise
    h2 = Math.imul(h2 ^ ch, 1597334677);
  }
  h1 =
    // eslint-disable-next-line no-bitwise
    Math.imul(h1 ^ (h1 >>> 16), 2246822507) ^
    // eslint-disable-next-line no-bitwise
    Math.imul(h2 ^ (h2 >>> 13), 3266489909);
  h2 =
    // eslint-disable-next-line no-bitwise
    Math.imul(h2 ^ (h2 >>> 16), 2246822507) ^
    // eslint-disable-next-line no-bitwise
    Math.imul(h1 ^ (h1 >>> 13), 3266489909);

  // eslint-disable-next-line no-bitwise
  return `${4294967296 * (2097151 & h2) + (h1 >>> 0)}`;
};
